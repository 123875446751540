<template>
  <el-date-picker
    v-model="valueLocal"
    v-bind="$attrs"
    :clearable="false"
    type="daterange"
    align="center"
    range-separator="-"
    :editable="editableDateRangeInput"
    start-placeholder="Start date"
    end-placeholder="End date"
    :picker-options="pickerOptionsLocal"
    class="DateRangePickerBase"
    data-testid="Filter__DateRangePicker"
  />
</template>

<script>
import { generateComputedSetterWithEmit } from '../../utils/componentUtilities'
import { getTimestampFromPredefinedSlug } from '../../vuex/utils/dateRange'

/**
 * @module DateRangePickerBase
 */
export default {
  name: 'DateRangePickerBase',
  props: {
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      required: true
    },
    shortcuts: {
      type: Array,
      default: () => []
    },
    editableDateRangeInput: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    valueLocal: generateComputedSetterWithEmit('value', 'input'),
    pickerOptionsLocal () {
      return {
        firstDayOfWeek: 1,
        disabledDate: time => time.getTime() > Date.now(),
        shortcuts: this.shortcuts.map((timespan) => ({
          text: this.$t(`components.main_dashboard.dashboard_filters.${timespan.label}`),
          onClick (picker) {
            const start = getTimestampFromPredefinedSlug(timespan.slug)
            const end = getTimestampFromPredefinedSlug()
            picker.$emit('pick', [start, end])
          }
        })),
        ...this.pickerOptions
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.DateRangePickerBase {
  width: auto !important;

  @include mobile {
    opacity: .5;
  }

  &.el-date-editor {
    background: transparent;
    width: auto;
    border: none;
    padding: 0;
    font-weight: bold;
    text-align: center;

    .el-input__icon {
      display: none;
    }

    .el-range-separator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      padding: 0;
    }

    .el-range-input {
      background: transparent;
      width: 80px;
      font-weight: 600;

      @include tablet {
        width: 110px;
        font-size: 18px;
      }

      &::placeholder {
        color: $hf__text-color;
      }
    }
  }
}
</style>
